


































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import StrategyInformationVideoViewModel
  from '@/vue-app/view-models/components/strategy-moderate-wealth/hiring-flow/strategy-information-video-view-model';

@Component({ name: 'StrategyInformationAndVideo' })
export default class StrategyInformationAndVideo extends Vue {
  @PropSync('showBackButton', { type: Boolean, required: false, default: true })
  synced_show_back_button!: boolean;

  strategy_information_video_view_model = Vue.observable(
    new StrategyInformationVideoViewModel(this),
  );

  created() {
    this.strategy_information_video_view_model.initialize();
  }
}

import TYPES from '@/types';

import Vue from 'vue';

// Application
import GetModerateInformationByEmailQuery
  from '@/modules/flagship/contact-points/sowos-moderate/application/queries/get-moderate-information-by-email-query';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class StrategyInformationVideoViewModel {
  @Inject(TYPES.GET_MODERATE_INFORMATION_BY_EMAIL_QUERY)
  private readonly get_moderate_information_by_email_query!: GetModerateInformationByEmailQuery;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly view: Vue;

  readonly i18n_namespace = 'components.strategy-moderate-wealth.strategy-information-video';

  video_src_webm = 'https://dv1k4uibauddz.cloudfront.net/sowos_moderado.webm';

  video_src_mp4 = 'https://dv1k4uibauddz.cloudfront.net/sowos_moderado.mp4';

  investment_product_fund_type_id = '304f7ce0-bdd1-4d99-afe4-4a6495e086c8';

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = () => {
    this.view.$emit('nextStep');
  }

  initialize = () => {
    this.view.$emit('setDynamicWidth', '60');
  }

  sendInformationByEmail = async () => {
    try {
      await this.get_moderate_information_by_email_query.execute(
        this.investment_product_fund_type_id,
      );
      this.message_notifier.showSuccessNotification(this.translate('success.information_sent'));
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.information_sent'));
    }
  }
}
